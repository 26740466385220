<template>
  <div class="home">
  <div class='myFlex'>
    <Tile :tile="acf.tile_1" :image="acf.tile_1.image" :id="7751"/>
    <Tile :tile="acf.tile_2" :image="acf.tile_2.image" :id="90"/>
    <Tile :tile="acf.tile_3" :image="acf.tile_3.image" :id="96"/>
    <Tile :tile="acf.tile_4" :image="acf.tile_4.image" :id="29545"/>
    <Tile :tile="acf.tile_5" :image="acf.tile_5.image" :id="9124"/>
    <Tile :tile="acf.tile_6" :image="acf.tile_6.image" :id="29162"/>
    <Tile :tile="acf.tile_7" :image="acf.tile_7.image" :id="2077"/>
    <Tile :tile="acf.tile_8" :image="acf.tile_8.image" :id="8695"/>
    <Tile :tile="acf.tile_9" :image="acf.tile_9.image" :id="98"/>
  </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Tile from '@/components/tile.vue'
export default {
  name: "Home",
  props:['config'],
  components:{Tile},
  data: function() {
    return {
      acf: [],
    };
  },
    created(){
   var json = "acf/v2/options"
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.acf = data.acf;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
.home{
  border-top: solid 10px #8ac2e6;
}
.myFlex{
  display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    flex-direction: unset;
    min-height: calc(103vh - calc(114px + 128px + 10px));
}
::v-deep .myFlex:hover .myItem:hover .myImg {
  transform: scale(1.1);
}
::v-deep .myFlex:hover .myItem .myContent {
  z-index: 1 !important;
}
::v-deep .myFlex:hover .myItem:not(:hover) .myOverlayTop {
  z-index: 2;
  background-color: rgba(0, 51, 83, 0.8);
}
@media (max-width: 991.98px){
.myFlex .myItem {
    flex-basis: 50%;
    min-height: 33.33%;
}
.myFlex{
      min-height: calc(100vh - calc(114px + 108px + 10px));
}
}
@media (max-width: 767.98px){
.myFlex {
    min-height: calc(100vh - calc(114px + 98px + 10px));
}
}
@media (max-width: 666.98px){
.myFlex .myItem {
    flex-basis: 100%;
    min-height: 16.66%;
}
}
</style>
