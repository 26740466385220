var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myItem"},[_c('div',{staticClass:"myOut"},[_c('div',{staticClass:"myImg tileImage",style:('background-image:url(' +
          _vm.imageFile.source_url +
          ');'),attrs:{"lg":"4"}})]),_c('div',{staticClass:"myOverlay"},[_c('div',{staticClass:"myOverlayTop"}),_c('div',{staticClass:"myContent"},[_c('h1',[_vm._v(" "+_vm._s(_vm.tile.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.tile.text)+" ")]),(_vm.comp.linkId === undefined)?_c('b-button',{staticClass:"myBtn",attrs:{"to":{
          name: 'latest-tour',
          query: {
            id: _vm.tile.title,
          },
        }}},[_vm._v(_vm._s(_vm.tile.button_text))]):(_vm.comp.link === 'none')?_c('b-button',{staticClass:"myBtn"},[_vm._v(" More Info ")]):_c('b-button',{staticClass:"myBtn",attrs:{"to":{
          name: _vm.comp.link,
          query: {
            id: _vm.comp.linkId,
          },
        }}},[_vm._v(_vm._s(_vm.tile.button_text))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }