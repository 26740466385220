<!-- Nav Menu -->

<template>
  <div id="app">
    <Menu :config="env"/>
    <router-view :key="$route.fullPath" :config="env"/>
    <Footer :config="env"/>
  </div>
</template>

<script>
import Menu from '@/components/menu.vue'
import Footer from '@/components/footer.vue'
export default {
  components:{Menu, Footer},
  data(){
    return{
      env:[]
    }
  },
  mounted(){
    this.env = process.env
  }
}
</script>

<style>
#app {
    margin: 0;
    font-family: "Rubik", sans-serif !important; 
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #737373;
    text-align: left;
    background-color: #fff;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 400!important;
    line-height: 1.2;
}

img{
  width: 100%;
}

</style>
