import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home
  },
  /* Top Level PAge */
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
    props: true
  },
  {
    path: "/covid-19-updates",
    name: "COVID-19 Updates",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/open-play",
    name: "OpenPlay",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/app",
    name: "App",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/handicapping",
    name: "Handicapping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/vms",
    name: "Venue Management System",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/club-support",
    name: "Club Support",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/national-junior-framework",
    name: "National Junior Framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/performance",
    name: "Performance",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/hive-login",
    name: "Hive Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/careers",
    name: "Careers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/about-us",
    name: "About Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutUs.vue"),
    props: true
  },
  {
    path: "/offical-partners",
    name: "Official Partners",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/vms-testimonials",
    name: "VMS Testimonials",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/topLevel.vue"),
    props: true
  },
  {
    path: "/competition",
    name: "competition",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/competition.vue"),
    props: true
  },
  {
    path: "/news",
    name: "News ",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/news-story",
    name: "news-story",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newStory.vue"),
    props: true
  },
  {
    path: "/latest-tour",
    name: "latest-tour",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
];

const router = new VueRouter({
  routes
});

export default router;
