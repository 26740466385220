<template>
  <div class="myItem">
    <div class="myOut">
      <div
        class="myImg tileImage"
        lg="4"
        :style="
          'background-image:url(' +
            imageFile.source_url +
            ');'
        "
      ></div>
    </div>
    <div class="myOverlay">
      <div class="myOverlayTop"></div>
      <div class="myContent">
        <h1>
          {{ tile.title }}
        </h1>
        <p>
          {{ tile.text }}
        </p>
        <b-button class="myBtn" v-if="comp.linkId === undefined" :to="{
            name: 'latest-tour',
            query: {
              id: tile.title,
            },
          }">{{ tile.button_text }}</b-button>
          <b-button class="myBtn" v-else-if="comp.link === 'none'">
            More Info
          </b-button>
        <b-button
        v-else
          :to="{
            name: comp.link,
            query: {
              id: comp.linkId,
            },
          }"
          class="myBtn"
          >{{ tile.button_text }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "tile",
  props: ["tile", "image", "id"],
  data() {
    return {
      imageFile: [],
    };
  },
  computed:{
    comp: function(link, linkArray, linkId){
      if(this.$route.name === 'home'){
      if (this.tile.title.includes('Live')){
      linkArray = this.tile.link.split('=')
      link = 'competition'
      linkId = linkArray[2]
      }
      else {link = this.tile.title
      linkId = this.id}
      }
      else {link = 'none', linkArray = 'none', linkId = this.id}
      return {link, linkId, linkArray} 
    }
  },
      created(){
   var json = "wp/v2/media/" + this.image
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.imageFile = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
/* .tileImage {
  background-repeat: no-repeat;
  background-size: inherit;
} */
.myImg {
  /* height: 100%;
  width: 100%;
  position: absolute;
  background-size: contain;
  transition: all 0.3s;
  transform: translateY(-57px); */
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}
.myItem {
  min-height: 50%;
  flex-basis: 33.33%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif !important;
  color: #f2f2f2;
  background-color: #f2f2f2;
  background-size: cover;
  background-position: center;
}
.myOut {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
}

.myOverlay {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  position: relative;
  padding: 6rem 4rem;
}
.myOverlayTop {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.myContent > h1 {
  font-size: 28px;
}
.myBtn {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  padding: 0.375rem 3rem;
  margin-top: 1rem;
  color: #0071b9;
}
</style>
