<template>
  <div>
    <b-navbar type="dark">
      <b-navbar-brand to="/"
        ><b-img class="logo" :src="logo.source_url"></b-img
      ></b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <p class="text">{{ acf.sg_header_text }}</p>
        <b-nav-item class="text" :href="'tel:' + acf.telephone_number">{{
          acf.telephone_number
        }}</b-nav-item>
        <b-nav-item class="login mobHide_1" to="/login"
          >Login/ Register</b-nav-item
        >
        <b-nav-item
          :href="'https://www.facebook.com/' + config.VUE_APP_FACEBOOK_NAME"
          class="login mobHide"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'facebook']"
        /></b-nav-item>
        <b-nav-item
          :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
          class="login mobHide"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'twitter']"
        /></b-nav-item>
        <b-nav-item
          :href="'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME"
          class="login mobHide"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'instagram']"
        /></b-nav-item>
        <b-nav-item
          :href="'https://www.youtube.com/c/' + config.VUE_APP_YOUTUBE_NAME"
          class="login mobHide"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'youtube']"
        /></b-nav-item>
        <b-nav-item
          :href="
            'https://www.linkedin.com/company/' + config.VUE_APP_LINKEDIN_NAME
          "
          class="login mobHide"
        >
          <font-awesome-icon id="fb" class="icon" :icon="['fab', 'linkedin']"
        /></b-nav-item>
        <b-button v-b-toggle.sidebar-right class="sideBtn btnBlock"
          ><b-img
            class="sideImg"
            :src="config.VUE_APP_WP_ASSETS + 'assets/grid.png'"
          ></b-img
        ></b-button>
        <b-sidebar id="sidebar-right" right shadow>
          <div class="px-3 py-2">
            <div class="links">
              <b-input-group class="mt-3 mb-3">
                <b-form-input
                  type="text"
                  v-model="search"
                  placeholder="Search"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    class="search"
                    :to="{
                      name: 'search',
                      query: {
                        id: search,
                      },
                    }"
                    ><font-awesome-icon class="sIcon" :icon="['fa', 'search']"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
              <b-nav-item class="sideLogin" to="/login"
                >Login/ Register</b-nav-item
              >
              <template v-for="(menu, index) in menuTitle">
                <b-nav-item
                  :key="index"
                  v-if="menu.title === 'Hive Login'"
                  class="sideLink"
                  href="https://scottishgolf.hivelearning.com/login"
                  >{{ menu.title }}</b-nav-item
                >
                <b-nav-item
                  :key="index"
                  v-else-if="menu.title === 'National Junior Framework'"
                  class="sideLink"
                  href="https://www.scottishjuniorgolf.org/"
                  >{{ menu.title }}</b-nav-item
                >
                <b-nav-item
                  v-else
                  :key="index"
                  class="sideLink"
                  :to="{
                    name: menu.title,
                    query: {
                      id: menu.object_id,
                    },
                  }"
                  >{{ menu.title }}</b-nav-item
                >
              </template>
              <b-navbar-nav>
                <b-nav-item class="sideLogin">
                  <font-awesome-icon
                    id="fb"
                    class="icon"
                    :icon="['fab', 'facebook']"
                /></b-nav-item>
                <b-nav-item class="sideLogin">
                  <font-awesome-icon
                    id="fb"
                    class="icon"
                    :icon="['fab', 'twitter']"
                /></b-nav-item>
                <b-nav-item class="sideLogin">
                  <font-awesome-icon
                    id="fb"
                    class="icon"
                    :icon="['fab', 'instagram']"
                /></b-nav-item>
                <b-nav-item class="sideLogin">
                  <font-awesome-icon
                    id="fb"
                    class="icon"
                    :icon="['fab', 'youtube']"
                /></b-nav-item>
                <b-nav-item class="sideLogin">
                  <font-awesome-icon
                    id="fb"
                    class="icon"
                    :icon="['fab', 'linkedin']"
                /></b-nav-item>
              </b-navbar-nav>
            </div>
          </div>
        </b-sidebar>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "menu",
  props: ["config"],
  data: function() {
    return {
      acf: [],
      logo: [],
      search: "",
      menuTitle: [],
    };
  },
  async created() {
    var one = "acf/v2/options";

    var three = "wp/v2/menu/";
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(one);
      this.acf = first_call.data.acf;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo("wp/v2/media/" + this.acf.sg_logo);
      this.logo = second_call.data;
      this.loading = false;
      let third_call = await apiCall.wpInfo(three);
      this.menuTitle = third_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.btnBlock {
  margin-top: -15px;
}
.navbar {
  padding: 1rem;
}
.logo {
  height: 80px;
  width: auto;
}

.login > a {
  color: #737373 !important;
}
p.text {
  padding: 0.5rem 1rem;
  color: #8ac2e6 !important;
  font-style: italic;
}
.sideLogin > a {
  color: #0071b9 !important;
}
.icon {
  height: 25px;
  width: auto;
  color: #b2b2b2;
}
.sideImg {
  height: 32px;
  width: auto;
}
.sideBtn {
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0 0 transparent;
}
.sideBtn:hover {
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0 0 transparent;
}
.sideBtn:focus {
  background-color: transparent;
  border: 0 !important;
  box-shadow: 0 0 0 0 transparent;
}
.sideBtn:active {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: 0 0 0 0 transparent;
}
::v-deep #sidebar-right {
  background-color: #8ac2e6 !important;
  color: white !important;
  font-size: 1.2rem;
  box-shadow: -1px 0px 5px 1px rgb(0 0 0 / 50%) !important;
}
.sideLink > a {
  color: #fff !important;
  padding: 0.5rem 3rem 0.5rem 1.5rem;
}
.sideLink {
  border-bottom: solid 1px #75b7e2;
}
::v-deep .form-group > div {
  display: inline-flex;
}
::v-deep .b-sidebar-header {
  height: 128px;
}
::v-deep .close {
  opacity: 1;
}
::v-deep .bi-x {
  color: white;
  font-size: 50pt;
}
::v-deep .input-group{
margin-bottom: 10px;
}
.search{
  background-color: #fff;
   border: 0px solid #fff
}
.form-control{
   border: 0px solid #fff
}
.sIcon{
  color: #737373;
 
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .mobHide_1 {
    display: none;
  }
  li.nav-item.login.mobHide_1 {
    display: none;
  }
}
</style>
