<template>
    <div class='sponImg' :style="'background-image: url(' + sponImg.source_url +');'"></div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
    name:'spon',
    props:['img'],
    data(){
        return{
            sponImg:[]
        }
    },
          created(){
   var json = "wp/v2/media/" + this.img
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.sponImg = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
}
</script>

<style scoped>
.sponImg{
    background-position: center;
    background-size: contain;
    height: 50px;
    background-repeat: no-repeat;
}

</style>