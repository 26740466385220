import axios from "axios";

class apiCall {
  tmParamsBase() {
    const api_url = process.env.VUE_APP_APIFULL_URL; 

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get("tmticx?randomadd=" + new Date().getTime());
  }
  tmParams(id, code) {
    const api_url = process.env.VUE_APP_APIFULL_URL ;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(
      id + "/" + id + "-" + code + "-tmticx?randomadd=" + new Date().getTime()
    );
  }
  tmParamsPlayer(id, playerID , code) {
    const api_url = process.env.VUE_APP_PLAYERAPI_URL + process.env.VUE_APP_LOGGEDIN_URL + code + "~player=" + playerID + "~tourns=y~seasons=y~season=" + id + "~enhanced=y~jsout=x~xsl=~?randomadd=" + new Date().getTime();

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(
      
    );
  }
  tmParamsYear(id) {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(id + "/tmticx?randomadd=" + new Date().getTime());
  }
  upComing(id) {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(id + "/tmticx?field=Y&fields=tournaments.tournaments_entry&days_away&randomadd=" + new Date().getTime()
    );
  }
  playercard(id, code ,card) {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(id + "/" + id + "-" + code + "-cards-" + card + ".json?randomadd=" + new Date().getTime()
    );
  }
  tmStats(id) {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(
      id +
        "/tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
        new Date().getTime()
    );
  }
  stat(id, stat) {
    const api_url = process.env.VUE_APP_APIFULL_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(
      id + "/" + id + stat + ".json?randomadd=" + new Date().getTime()
    );
  }
  wpInfo(json) {
    const api_url = process.env.VUE_APP_WPAPI_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(json);
  }
  report(report) {
    const api_url = report;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get();
  }
  players() {
    const api_url = process.env.VUE_APP_PLAYAPI_URL;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get();
  }
  playersInfo(refno) {
    const api_url =
      process.env.VUE_APP_PLAYERAPI_URL +
      process.env.VUE_APP_TIC_BASE +
      "/media/" +
      refno +
      ".xml?randomadd=" +
      new Date().getTime();

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get();
  }
  playersEnhance(refno, id) {
    const api_url =
    process.env.VUE_APP_PLAYERAPI_URL  + process.env.VUE_APP_TIC_BASE + "/tic/tmplayer.cgi?player=" +
    refno +
    "~season=" +
    id +
    "~enhanced=y~jsout=x~xsl=~?randomadd=" +
    new Date().getTime()

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get();
  }
  playersArea() {
    const api_url =
      process.env.VUE_APP_PLAYERAPI_URL +
      process.env.VUE_APP_TIC_BASE +
      "/tic/tmticx.cgi?tourns=y~seasons=y~enhanced=y~jsout=x~xsl=~?randomadd=" +
      new Date().getTime();

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get();
  }
  youTubeInsta(call) {
    const api_url = call;

    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get();
  }
}

export default new apiCall();
