<template>
  <div>
    <div class="slider">
      <VueSlickCarousel
        :autoplay="true"
        :infinite="true"
        :speed="1000"
        :autoplaySpeed="6000"
        :slidesToShow="8"
      >
        <div>
          <Sponsor :img="spons[0].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[1].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[2].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[3].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[4].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[5].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[6].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[7].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[8].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[9].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[10].acf.thumbnail" />
        </div>
        <div>
          <Sponsor :img="spons[11].acf.thumbnail" />
        </div>
      </VueSlickCarousel>
    </div>
    <div v-if="this.$route.name !== 'home'">
      <div class="lightB">
        <p>
          © Scottish Golf Limited 2021. All rights reserved. Scottish Golf,
          Arrol House, Rosyth Europarc, Viking Way, Rosyth, KY11 2UU.
          <a href="tel:01334 466477">Tel: 01334 466477</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Sponsor from "@/components/sponsor.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "footer",
  components: { VueSlickCarousel, Sponsor },
  data() {
    return {
      spons: [],
    };
  },
  created(){
   var json = "wp/v2/sponsor?per_page=20"
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.spons = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
::v-deep .slick-slider > button {
  display: none !important;
}
.slider {
  padding: 2rem 1rem;
}
.lightB {
  padding: 1.5rem 1rem .5rem 1rem;;
  background-color: #8ac2e6;
  color: #ffffff;
}
.lightB>p>a{
        color: #fff;
}
</style>
